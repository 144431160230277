import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter);

const authChildRoutes = (prop) => [
    {
        path:'login',
        name: prop + '.login',
        component:() => import('@/components/Login.vue')
    },
    {
        path:'otp',
        name: prop + '.otp',
        component: () => import('@/components/Otp.vue')
    }
]

const otherRoutes = () => [
    {
        path:'/',
        name:'dashboard',
        component:() => import('./components/Dashboard.vue'),
        meta: {  requiresAuth:true }
    },
    {
        path:'/pickup',
        name:'pickup',
        component:() => import('./components/Pickup.vue'),
        meta: {  requiresAuth:true }
    },
    {
        path:'/order',
        name:'order',
        component:() => import('./components/Order.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/return',
        name:'return',
        component:() => import('./components/return/List.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/return-show',
        name:'return.show',
        component:() => import('./components/return/Show.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/advanced-salary',
        name:'AdvancedSalary',
        component:() => import('./components/AdvancedSalary.vue'),     
        meta: { requiresAuth:true }       
    },
    {
        path:'/deposit-report',
        name:'deposit_report',
        component:() => import('./components/DepositReport.vue'),     
        meta: { requiresAuth:true }       
    },
    
]

const routes = [
    {
        path: '/auth',
        name: 'auth',
        component: () => import('@/components/layouts/AuthLayout.vue'),
        meta: { auth: false },
        children: authChildRoutes('auth')
    },    
    {
        path: '/',
        name: 'main',
        component: () => import('@/components/layouts/MainLayout.vue'),
        meta: { auth: false },
        children: otherRoutes()
    },    
    {
        path: '*',
        component: () => import('@/components/NotFoundPage.vue'),
        meta: { hideNavigation: true }
    }
];

export const router = new VueRouter({
    mode: "history",
    hash: false,
    base: process.env.BASE_URL,
    hashbang: false,
    routes
})
  
router.beforeEach((to, from, next) => {  
    if (to.matched.some(record => record.meta.requiresAuth)) {
        const token = localStorage.getItem('accessToken');
        if (!token) {
            next({
                path: '/auth/login',
                query: { redirect: to.fullPath }
            })
        } else {
            next();
        }
    } else {
      next();
    }
})

export default router
